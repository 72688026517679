// Components
import ToastificationContent from "../components/toastification/ToastificationContent.vue"

// Utils
import { handleAPIErrors } from "../utils/fetch-utils"

export default {
  methods: {
    handleFetchError(e, errorMessage) {
      const errors = handleAPIErrors(e, errorMessage)

      if (errors && errors.length) {
        this.showErrorToast("Por favor, intenta de nuevo.", errors[0])
      }
    },

    showSuccessToast(title, text, icon = "CheckCircleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant: "success",
          text,
        },
      })
    },

    warningToast(title, text, icon = "AlertTriangleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant: "warning",
          text,
        },
      })
    },

    successToast(title, text, icon = "CheckCircleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant: "success",
        },
      })
    },

    errorToast(title, text, icon = "AlertTriangleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant: "danger",
        },
      })
    },

    fetchErrorToast(e, errorMessage) {
      const errors = handleAPIErrors(e, errorMessage)

      if (errors && errors.length) {
        this.showErrorToast("Por favor, intenta de nuevo.", errors[0])
      }
    },

    showErrorToast(title, text, icon = "AlertTriangleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant: "danger",
          text,
        },
      })
    },
  },
}
