<template>
  <GmapMap
    ref="map"
    :center="center"
    :zoom="zoom"
    :style="`width: 100%; height: 300px`"
    map-type-id="roadmap"
    :clickable="false"
    :options="{
      streetViewControl: false,
      fullscreenControl: true,
      restriction: {
        latLngBounds: {
          north: 32.718,
          south: 14.532,
          west: -118.503,
          east: -86.589,
        },
        strictBounds: false,
      },
    }"
  >
    <GmapMarker :position="marker" :clickable="false" />
  </GmapMap>
</template>

<script>
export default {
  props: {
    zoom: {
      type: Number,
      default: 10,
    },
    lat: {
      type: Number,
      default: 19.4326077,
    },
    lng: {
      type: Number,
      default: -99.133208,
    },
  },
  data() {
    return {
      center: { lat: 19.4326077, lng: -99.133208 },
      marker: { lat: 19.4326077, lng: -99.133208 },
    }
  },
  mounted() {
    this.center = { lat: this.lat, lng: this.lng } 
    this.marker = { lat: this.lat, lng: this.lng }
  },
}
</script>
