export const handleAPIErrors = (error, errorMessage) => {
  let errors = []
  
  if (error.messages) {
    if (error.messages.base) {
      errors.push(...error.messages.base)
    } else if (typeof error.messages === "object") {
      errors.push(...error.messages)
    } else if (typeof error.messages === "string") {
      errors.push(error.messages)
    } else {
      errors.push(error.messages[0])
    }
  } else {
    errors.push(errorMessage)
  }

  return errors.flat()
}
