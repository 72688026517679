<template>
  <section class="invoice-preview-wrapper">
    <b-row v-if="pendingOrder" class="invoice-preview">
      <b-col cols="12">
        <b-card no-body class="invoice-preview-card p-2">
          <b-card-body class="invoice-padding pb-0">
            <div
              class="
                d-flex
                justify-content-between
                flex-md-row flex-column
                invoice-spacing
                mt-0
              "
            >
              <div v-if="pendingOrder.store">
                <div class="logo-wrapper">
                  <b-img
                    id="logo"
                    :src="pendingOrder.store.logo"
                    height="50"
                    alt="logo"
                  />
                </div>
                <p class="card-text mb-25">
                  {{ pendingOrder.store.name }}
                </p>
              </div>

              <div class="mt-md-0 mt-2">
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Fecha:</p>
                  <p class="invoice-date">
                    {{ parsedOrder.created_at | fullDate }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Hora:</p>
                  <p class="invoice-date">
                    {{ parsedOrder.created_at | time }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <hr class="invoice-spacing" />

          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <b-col xl="4" cols="12" class="p-0">
                <h4 class="mb-0.4">Detalles:</h4>
                <table>
                  <tbody>
                    <tr
                      v-if="currentUser && currentUser.role_name === 'customer'"
                    >
                      <td class="pr-1">Cliente:</td>
                      <td class="font-weight-bolder">
                        {{ currentUser.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">Tipo:</td>
                      <td class="font-weight-bolder">
                        {{ parsedOrder.order_type | orderType }}
                        {{ parsedOrder.order_mode | orderMode }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">Tipo de pago:</td>
                      <td class="font-weight-bolder">
                        <span
                          v-if="
                            parsedOrder.order_payments_attributes &&
                            parsedOrder.order_payments_attributes[0]
                          "
                        >
                          {{
                            parsedOrder.order_payments_attributes[0]
                              .payment_type | paymentType
                          }}
                        </span>
                        <span v-else class="uppercase">
                          {{ parsedOrder.payment_type }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="pr-1">Estado de pago:</td>
                      <td class="font-weight-bolder">
                        {{ pendingOrder.payment_status | paymentStatus }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">¿Es devolución?:</td>
                      <td class="font-weight-bolder">
                        {{ parsedOrder.is_refund === true ? "Si" : "No" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">Total:</td>
                      <td class="font-weight-bolder">
                        ${{ parsedOrder.total | money }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col class="p-0 mt-2">
                <b-table-lite
                  v-if="pendingOrder.store_products.length > 0"
                  responsive
                  :items="pendingOrder.store_products"
                  :fields="[
                    { key: 'name', label: 'producto' },
                    { key: 'unit_price', label: 'precio' },
                    { key: 'units', label: 'unidades' },
                    'total',
                  ]"
                >
                  <template #cell(name)="data">
                    <b-card-text class="font-weight-bold mb-25 d-flex">
                      <b-img
                        width="40px"
                        height="40px"
                        :src="`${data.item.product_attributes.logo}`"
                        rounded
                        class="mr-1"
                      />
                      <div>
                        {{ data.item.product_attributes.name }}
                        <div v-if="data.item.product_attributes.variant">
                          <b-badge class="mr-1 mt-1" pill variant="primary">
                            {{ data.item.product_attributes.variant }}
                          </b-badge>
                        </div>

                        <div
                          v-if="data.item.product_attributes.categories_names"
                        >
                          <b-badge
                            v-for="category in data.item.product_attributes
                              .categories_names"
                            :key="`category-${category}`"
                            variant="light-info"
                            class="mr-1 mt-1"
                          >
                            {{ category }}
                          </b-badge>
                        </div>
                      </div>
                    </b-card-text>
                  </template>
                  <template #cell(unit_price)="data">
                    <b-card-text class="font-weight-bold mb-25">
                      ${{ data.item.unit_price | money }}
                    </b-card-text>
                  </template>
                  <template #cell(units)="data">
                    <b-card-text class="font-weight-bold mb-25">
                      {{
                        parsedOrder.order_store_products_attributes.find(
                          (o) => o.store_product_id === data.item.id
                        ).units
                      }}
                    </b-card-text>
                  </template>
                  <template #cell(total)="data">
                    <b-card-text class="font-weight-bold mb-25">
                      {{
                        (parsedOrder.order_store_products_attributes.find(
                          (o) => o.store_product_id === data.item.id
                        ).units *
                          data.item.unit_price)
                          | money
                      }}
                    </b-card-text>
                  </template>
                </b-table-lite>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col
                xl="6"
                cols="12"
                class="py-2 px-0"
                v-if="
                  parsedOrder.address_attributes &&
                  parsedOrder.delivery_option &&
                  parsedOrder.delivery_option === 'delivery'
                "
              >
                <h4 class="mb-0.4">Detalles de envio:</h4>
                <p class="pb-1">
                  <span v-if="parsedOrder.address_attributes.street">
                    {{ parsedOrder.address_attributes.street }}
                  </span>
                  <span v-if="parsedOrder.address_attributes.ext_number">
                    {{ parsedOrder.address_attributes.ext_number }}
                  </span>
                  <span v-if="parsedOrder.address_attributes.int_number">
                    (num. int {{ parsedOrder.address_attributes.int_number }} )
                  </span>
                  <span v-if="parsedOrder.address_attributes.postal_code">
                    CP: {{ parsedOrder.address_attributes.postal_code }}
                  </span>
                  <span v-if="parsedOrder.address_attributes.city">
                    {{ parsedOrder.address_attributes.city }}
                  </span>
                  <span v-if="parsedOrder.address_attributes.state">
                    {{ parsedOrder.address_attributes.state }}
                  </span>
                  <span v-if="parsedOrder.address_attributes.country">
                    {{ parsedOrder.address_attributes.country }}
                  </span>
                </p>
                <GoogleMap
                  v-if="
                    parsedOrder.address_attributes.lat &&
                    parsedOrder.address_attributes.lng
                  "
                  :zoom="15"
                  :lat="parseFloat(parsedOrder.address_attributes.lat)"
                  :lng="parseFloat(parsedOrder.address_attributes.lng)"
                />
              </b-col>
              <b-col
                cols="12"
                class="py-2 px-0"
                v-if="
                  pendingOrder.store &&
                  pendingOrder.store.address_attributes &&
                  parsedOrder.delivery_option === 'pickup'
                "
              >
                <h4 class="mb-0.4">Detalles de recolección:</h4>
                <p class="pb-1">
                  <span v-if="pendingOrder.store.address_attributes.street">
                    {{ pendingOrder.store.address_attributes.street }}
                  </span>
                  <span v-if="pendingOrder.store.address_attributes.ext_number">
                    {{ pendingOrder.store.address_attributes.ext_number }}
                  </span>
                  <span v-if="pendingOrder.store.address_attributes.int_number">
                    (num. int
                    {{ pendingOrder.store.address_attributes.int_number }} )
                  </span>
                  <span
                    v-if="pendingOrder.store.address_attributes.postal_code"
                  >
                    CP: {{ pendingOrder.store.address_attributes.postal_code }}
                  </span>
                  <span v-if="pendingOrder.store.address_attributes.city">
                    {{ pendingOrder.store.address_attributes.city }}
                  </span>
                  <span v-if="pendingOrder.store.address_attributes.state">
                    {{ pendingOrder.store.address_attributes.state }}
                  </span>
                  <span v-if="pendingOrder.store.address_attributes.country">
                    {{ pendingOrder.store.address_attributes.country }}
                  </span>
                </p>
                <GoogleMap
                  v-if="
                    pendingOrder.store.address_attributes.lat &&
                    pendingOrder.store.address_attributes.lng
                  "
                  :zoom="15"
                  :lat="parseFloat(pendingOrder.store.address_attributes.lat)"
                  :lng="parseFloat(pendingOrder.store.address_attributes.lng)"
                />
              </b-col>
            </b-row>
          </b-card-body>

          <b-card-body class="invoice-padding pt-3">
            <span class="font-weight-bold">Nota: </span>
            <span>Gracias por tu preferencia! ✌</span>
          </b-card-body>
          <b-button
            v-ripple
            variant="primary"
            class="invoice-send"
            :disabled="loading"
            @click="handleShowDetails"
          >
            <b-spinner v-if="loading" small />
            <span v-else>Mostrar detalles</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
// Directives
import { mapGetters } from "vuex"

// Components
import GoogleMap from "./GoogleMap.vue"

export default {
  components: {
    GoogleMap,
  },
  props: {
    pendingOrder: {
      type: Object,
      default: () => {},
    },
    parsedOrder: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    handleShowDetails: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  body.modal-open {
    visibility: hidden;
  }

  body.modal-open .modal .modal-body {
    visibility: visible; /* make visible modal body and header */
  }
  #logo {
    filter: invert(1);
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .pos-layout {
    display: none !important;
  }
  .hide-print,
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.btnx {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
}

/* Primary button style */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* Secondary button style */
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border: none;
}

.btn-secondary:hover {
  background-color: #545b62;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
</style>
