<template>
  <section class="invoice-preview-wrapper">
    <b-row v-if="order && currentUser" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="
                d-flex
                justify-content-between
                flex-md-row flex-column
                invoice-spacing
                mt-0
              "
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img id="logo" :src="appLogoImage" height="50" alt="logo" />
                </div>
                <p class="card-text mb-25">
                  {{ order.store.establishment_attributes.name }}
                </p>
                <p class="card-text mb-25">
                  {{ order.store.name }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Ticket
                  <span class="invoice-number">
                    #{{ order.auto_inc_folio }}
                  </span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Fecha:</p>
                  <p class="invoice-date">
                    {{ order.created_at | fullDate }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Hora:</p>
                  <p class="invoice-date">
                    {{ order.created_at | time }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <div
            v-if="
              order.is_refund === true &&
              order.delivery_status === 'cancelled_by_customer'
            "
          >
            <b-card style="color: red" no-body class="text-center">
              <div class="bg-danger text-light">
                Esta compra ha sido cancelada por el cliente
              </div>
            </b-card>
          </div>

          <div
            v-if="
              order.is_refund === true &&
              order.delivery_status === 'cancelled_by_establishment'
            "
          >
            <b-card style="color: red" no-body class="text-center">
              <div class="bg-danger text-light">
                Esta compra ha sido cancelada por administrador del negocio
              </div>
            </b-card>
          </div>

          <div
            v-if="
              order.delivery_status === 'rejected_by_dispatcher' &&
              currentUser.role_name === 'dispatcher'
            "
          >
            <b-card style="color: red" no-body class="text-center">
              <div class="bg-danger text-light">Rechazaste esta entrega</div>
            </b-card>
          </div>

          <div
            v-if="
              order.delivery_status === 'delivered' &&
              order.order_mode === 'online'
            "
          >
            <b-card style="color: red" no-body class="text-center">
              <div class="bg-success text-light">Compra entregada</div>
            </b-card>
          </div>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Order & Payment Details -->
          <div class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Orer Details -->
              <b-col xl="4" cols="12" class="p-0">
                <div>
                  <h4 class="mb-0.4">Detalles:</h4>
                  <table>
                    <tbody>
                      <tr v-if="currentUser.role_name === 'customer'">
                        <td class="pr-1">Cliente:</td>
                        <td class="font-weight-bolder">
                          {{ currentUser.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Tipo:</td>
                        <td class="font-weight-bolder">
                          {{ order.order_type | orderType }}
                          {{ order.order_mode | orderMode }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Tipo de pago:</td>
                        <td class="font-weight-bolder">
                          <span v-if="order.order_payments_attributes[0]">
                            {{
                              order.order_payments_attributes[0].payment_type
                                | paymentType
                            }}
                          </span>
                          <span v-else>
                            {{ order.payment_type | paymentTypeNum }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Estado de pago:</td>
                        <td class="font-weight-bolder">
                          {{ order.payment_status | paymentStatus }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          !order.delivery_address &&
                          order.delivery_option !== 'pickup'
                        "
                      >
                        <td class="pr-1">¿Es fiado?:</td>
                        <td class="font-weight-bolder">
                          {{ order.is_loan === true ? "Si" : "No" }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          !order.delivery_address &&
                          order.delivery_option !== 'pickup'
                        "
                      >
                        <td class="pr-1">¿Es devolución?:</td>
                        <td class="font-weight-bolder">
                          {{ order.is_refund === true ? "Si" : "No" }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          order.order_mode !== 'onsite' &&
                          (currentUser.role_name === 'customer' ||
                            currentUser.role_name === 'establishment_admin') &&
                          !order.delivery_address &&
                          order.delivery_option !== 'pickup'
                        "
                      >
                        <td class="pr-1">Entregar en:</td>
                        <td class="font-weight-bolder">
                          {{ order.delivery_location.street }}
                          {{ order.delivery_location.ext_number }} (num. int
                          {{ order.delivery_location.int_number }} ), CP:
                          {{ order.delivery_location.postal_code }},
                          {{ order.delivery_location.city }},
                          {{ order.delivery_location.state }},
                          {{ order.delivery_location.country }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          (order.order_mode !== 'onsite' &&
                            currentUser.role_name === 'dispatcher' &&
                            (order.delivery_status === 'on_the_way' ||
                              order.delivery_status === 'delivered')) ||
                          (order.delivery_location &&
                            !order.delivery_address &&
                            order.delivery_option !== 'pickup')
                        "
                      >
                        <td class="pr-1">Entregar en:</td>
                        <td class="font-weight-bolder">
                          {{ order.delivery_location.street }}
                          {{ order.delivery_location.ext_number }} (num. int
                          {{ order.delivery_location.int_number }} ), CP:
                          {{ order.delivery_location.postal_code }},
                          {{ order.delivery_location.city }},
                          {{ order.delivery_location.state }},
                          {{ order.delivery_location.country }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          order.order_mode !== 'onsite' &&
                          currentUser.role_name === 'dispatcher' &&
                          (order.delivery_status === 'assigned' ||
                            order.delivery_status === 'assigning') &&
                          order.delivery_location &&
                          !order.delivery_address &&
                          order.delivery_option !== 'pickup'
                        "
                      >
                        <td class="pr-1">Entregar en (aprox):</td>
                        <td class="font-weight-bolder">
                          {{ order.delivery_location.street }}, CP:
                          {{ order.delivery_location.postal_code }},
                          {{ order.delivery_location.city }},
                          {{ order.delivery_location.state }},
                          {{ order.delivery_location.country }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Te atendió:</td>
                        <td class="font-weight-bolder">
                          {{ order.store_clerk.name }}
                        </td>
                      </tr>
                      <template v-if="isFlowTypeB">
                        <tr>
                          <td class="pr-1">Subtotal:</td>
                          <td class="font-weight-bolder">
                            ${{
                              (Number(order.total) -
                                Number(
                                  JSON.parse(order.emida_response.request).fee
                                ))
                                | money
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">Comisión:</td>
                          <td class="font-weight-bolder">
                            ${{
                              JSON.parse(order.emida_response.request).fee
                                | money
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">Total:</td>
                          <td class="font-weight-bolder">
                            ${{ order.total | money }}
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td class="pr-1">Subtotal:</td>
                          <td class="font-weight-bolder">
                            ${{ order.subtotal | money }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">IVA:</td>
                          <td class="font-weight-bolder">
                            ${{ order.iva | money }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">Total:</td>
                          <td class="font-weight-bolder">
                            ${{ order.total | money }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>

                  <b-card
                    v-if="
                      order.order_mode !== 'onsite' &&
                      (order.delivery_status === 'assigning' ||
                        order.delivery_status === 'assigned') &&
                      order.is_refund === false &&
                      currentUser.role_name === 'dispatcher'
                    "
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="success"
                      class="mb-75"
                      block
                      @click="dispatcherConfirms()"
                    >
                      Aceptar entrega
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="danger"
                      class="mb-75"
                      block
                      @click="dispatcherRejects()"
                    >
                      Rechazar entrega
                    </b-button>
                    <b-button
                      v-if="
                        (order.order_mode !== 'onsite' &&
                          order.delivery_status === 'on_the_way' &&
                          order.is_refund === false &&
                          currentUser.role_name === 'dispatcher') ||
                        (order.order_mode !== 'onsite' &&
                          order.delivery_status !== 'delivered' &&
                          order.is_refund === false &&
                          currentUser.role_name === 'establishment_admin')
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="warning"
                      class="mb-75"
                      block
                      :href="
                        'https://www.google.com/maps/search/?api=1&query=' +
                        order.delivery_location.lat +
                        '%2C' +
                        order.delivery_location.lng
                      "
                    >
                      <feather-icon icon="MapIcon" size="18" />
                      Ver mapa
                    </b-button>
                    <b-button
                      v-if="
                        (order.order_mode !== 'onsite' &&
                          order.delivery_status === 'on_the_way' &&
                          order.is_refund === false &&
                          currentUser.role_name === 'dispatcher') ||
                        (order.order_mode !== 'onsite' &&
                          order.delivery_status !== 'delivered' &&
                          order.is_refund === false &&
                          currentUser.role_name === 'establishment_admin')
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="success"
                      class="mb-75"
                      block
                      @click="$bvModal.show('mark-as-delivered')"
                    >
                      Finalizar entrega
                    </b-button>
                  </b-card>
                </div>
              </b-col>
              <b-col class="mt-2 p-0"v-if="order.order_store_products_attributes.length > 0">
                <b-table-lite
                  responsive
                  :items="order.order_store_products_attributes"
                  :fields="[
                    { key: 'name', label: 'producto' },
                    { key: 'unit_price', label: 'precio' },
                    { key: 'units', label: 'unidades' },
                    'total',
                  ]"
                >
                  <template #cell(name)="data">
                    <b-card-text class="font-weight-bold mb-25 d-flex">
                      <b-img
                        width="40px"
                        height="40px"
                        :src="`${data.item.store_product_attributes.product_attributes.logo}`"
                        rounded
                        class="mr-1"
                      />
                      <div>
                        {{
                          data.item.store_product_attributes.product_attributes
                            .name
                        }}
                        <div>
                          <b-badge
                            v-for="(variant, n) in data.item
                              .store_product_attributes
                              .store_product_variant_attributes"
                            :key="`variant-${n}`"
                            class="mr-1 mt-1"
                            pill
                            variant="primary"
                          >
                            {{ variant.variant_option_attributes.option_name }}
                          </b-badge>
                        </div>
                        <div>
                          <b-badge
                            v-for="category in data.item
                              .store_product_attributes.product_attributes
                              .categories_names"
                            :key="`category-${category}`"
                            variant="light-info"
                            class="mr-1 mt-1"
                          >
                            {{ category }}
                          </b-badge>
                        </div>
                      </div>
                    </b-card-text>
                  </template>
                </b-table-lite>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="
                  order.delivery_address &&
                  order.delivery_option &&
                  order.delivery_option === 'delivery'
                "
              >
                <h4 class="mb-0.4">Detalles de envio:</h4>
                <p class="pb-1">
                  <span v-if="order.delivery_address.street">
                    {{ order.delivery_address.street }}
                  </span>
                  <span v-if="order.delivery_address.ext_number">
                    {{ order.delivery_address.ext_number }}
                  </span>
                  <span v-if="order.delivery_address.int_number">
                    (num. int {{ order.delivery_address.int_number }} )
                  </span>
                  <span v-if="order.delivery_address.postal_code">
                    CP: {{ order.delivery_address.postal_code }}
                  </span>
                  <span v-if="order.delivery_address.city">
                    {{ order.delivery_address.city }}
                  </span>
                  <span v-if="order.delivery_address.state">
                    {{ order.delivery_address.state }}
                  </span>
                  <span v-if="order.delivery_address.country">
                    {{ order.delivery_address.country }}
                  </span>
                </p>
                <GoogleMap
                  v-if="
                    order.delivery_address.lat && order.delivery_address.lng
                  "
                  :zoom="15"
                  :lat="parseFloat(order.delivery_address.lat)"
                  :lng="parseFloat(order.delivery_address.lng)"
                />
              </b-col>
              <b-col
                v-if="
                  order.delivery_option === 'pickup' &&
                  order.store.address_attributes
                "
              >
                <h4 class="mb-0.4 mt-2">Detalles de recolección:</h4>
                <p class="pb-1">
                  <span v-if="order.store.address_attributes.street">
                    {{ order.store.address_attributes.street }}
                  </span>
                  <span v-if="order.store.address_attributes.ext_number">
                    {{ order.store.address_attributes.ext_number }}
                  </span>
                  <span v-if="order.store.address_attributes.int_number">
                    (num. int
                    {{ order.store.address_attributes.int_number }} )
                  </span>
                  <span v-if="order.store.address_attributes.postal_code">
                    CP: {{ order.store.address_attributes.postal_code }}
                  </span>
                  <span v-if="order.store.address_attributes.city">
                    {{ order.store.address_attributes.city }}
                  </span>
                  <span v-if="order.store.address_attributes.state">
                    {{ order.store.address_attributes.state }}
                  </span>
                  <span v-if="order.store.address_attributes.country">
                    {{ order.store.address_attributes.country }}
                  </span>
                </p>
                <GoogleMap
                  v-if="
                    order.store.address_attributes.lat &&
                    order.store.address_attributes.lng
                  "
                  :zoom="15"
                  :lat="parseFloat(order.store.address_attributes.lat)"
                  :lng="parseFloat(order.store.address_attributes.lng)"
                />
              </b-col>
            </b-row>
          </div>

          <!-- Emida products table -->
          <table
            v-if="order.emida_response"
            class="table-responsive table b-table"
          >
            <thead style="background-color: #343d55">
              <th>PRODUCTO</th>
              <th>REFERENCIA</th>
              <th>CÓDIGO DE AUTORIZACIÓN</th>
            </thead>
            <tr>
              <td>
                <div>
                  {{ JSON.parse(order.emida_response.request).productName }}
                  <div>
                    <p class="font-weight-bold">
                      Nota:
                      <span class="text-muted">
                        {{ order.emida_response.response_message }}
                      </span>
                    </p>
                  </div>
                </div>
              </td>
              <td>{{ order.emida_response.pin }}</td>
              <td>{{ order.emida_response.carrier_control_no }}</td>
            </tr>
          </table>

          <!-- Invoice Description: Table for store_products -->
          <b-modal
            id="mark-as-delivered"
            title="Confirmar entrega a cliente"
            no-stacking
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            ok-title="Finalizar"
            ok-variant="success"
            cancel-title="Cancelar"
            cancel-variant="outline-danger"
            @ok="dispatcherConfirmsDelivery()"
          >
            <b-card>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="50"
                  class="mr-1"
                  icon="AlertTriangleIcon"
                  style="color:orange;'"
                />
                <b-card-title
                  v-if="order.payment_status === 'pending'"
                  class="ml-25"
                  style="color:orange;'"
                >
                  Antes de entregar cobra al cliente ${{ order.total | money }}
                </b-card-title>
                <b-card-title
                  v-if="order.payment_status === 'payed'"
                  class="ml-25"
                >
                  ¿Estás seguro que quieres marcar como entregado?
                </b-card-title>
              </div>
            </b-card>
          </b-modal>

          <!-- Note -->
          <b-card-body class="invoice-padding pt-3">
            <span class="font-weight-bold">Nota: </span>
            <span>Gracias por tu preferencia! ✌</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <b-button
            v-if="
              ((order.order_mode === 'onsite' ||
                order.delivery_status !== 'delivered') &&
                order.is_refund === false &&
                (currentUser.role_name !== 'dispatcher' ||
                  currentUser.role_name !== 'store_clerk') &&
                order.order_payments_attributes[0].payment_type !==
                  'bankcard') ||
              order.order_payments_attributes[0].payment_type === 'bank_deposit'
            "
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            class="mb-75"
            block
            @click="confirmCancelation"
          >
            Cancelar orden
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimir
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
// Directives
import { ref } from "@vue/composition-api"
import { mapActions, mapGetters } from "vuex"

// Components
import GoogleMap from "./GoogleMap.vue"

// Utils
import { $themeConfig } from "@themeConfig"

export default {
  components: {
    GoogleMap,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})

    const invoiceDescription = [
      {
        taskTitle: "Native App Development",
        taskDescription:
          "Developed a full stack native app using React Native, Bootstrap & Python",
        rate: "$60.00",
        hours: "30",
        total: "$1,800.00",
      },
      {
        taskTitle: "UI Kit Design",
        taskDescription:
          "Designed a UI kit for native app using Sketch, Figma & Adobe XD",
        rate: "$60.00",
        hours: "20",
        total: "$1200.00",
      },
    ]

    const { appLogoImage } = $themeConfig.app

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      appLogoImage,
    }
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      locationLink: null,
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),

    isFlowTypeB() {
      return (
        this.order.emida_response && this.order.emida_response.flow_type === "b"
      )
    },
  },
  methods: {
    ...mapActions("orders", [
      "fetchOrder",
      "sellerCancelsOnlineOrder",
      "cancelOnlineOrder",
      "deliveryConfirmation",
      "confirmedByDispatcher",
      "rejectedByDispatcher",
    ]),
    ...mapActions("printer", ["printEmidaInvoice"]),
    ...mapActions("pos", ["emptyCart"]),
    eliminarDiacriticos(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    },
    dispatcherConfirms() {
      this.locationLink =
        "https://www.google.com/maps/search/?api=1&query=" +
        `${this.order.delivery_location.lat}` +
        "%2C" +
        `${this.order.delivery_location.lng}`
      this.confirmedByDispatcher({
        id: this.order.id,
      })
        .then((response) => {
          this.$swal({
            title: "Orden aceptada!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    dispatcherRejects() {
      this.rejectedByDispatcher({
        id: this.order.id,
      })
        .then((response) => {
          this.$swal({
            title: "Orden rechazada!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    dispatcherConfirmsDelivery() {
      this.deliveryConfirmation({
        id: this.order.id,
      })
        .then((response) => {
          this.$swal({
            title: "Orden marcada como entrega!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    cancelOrderByEstablishment() {
      this.sellerCancelsOnlineOrder({ id: this.order.id })
        .then((response) => {
          this.$swal({
            title: "Orden cancelada!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            confirmButtonText: "Ok",
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    confirmCancelation() {
      this.$swal({
        title: "¿Estás seguro que deseas cancelar esta orden?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, cancelar",
        cancelButtonText: "NO",
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.currentUser.customer) {
            this.cancelOrderByCustomer()
          } else {
            this.cancelOrderByEstablishment()
          }
        }
      })
    },
    cancelOrderByCustomer() {
      this.cancelOnlineOrder(this.order)
        .then((response) => {
          this.$swal({
            title: "Orden cancelada!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            confirmButtonText: "Ok",
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages[0],
            },
          })
        })
    },
    printInvoice() {
      if (this.order.order_type === "emida") {
        if (JSON.parse(localStorage.getItem("print")) === true) {
          const parsedRequest = JSON.parse(this.order.emida_response.request)
          const orderToPrint = {
            order_type: {
              payment: this.order.order_payments_attributes[0].payment_type,
              type: "sell",
            },
            store: this.eliminarDiacriticos(this.order.store.name),
            products: {
              name: parsedRequest.productName,
              important_info: this.order.emida_response.response_message,
              autorization_code: this.order.emida_response.carrier_control_no,
              refence: this.order.emida_response.pin,
              unit_price: Number(this.order.total).toFixed(2),
            },
            total: Number(this.order.total),
            date: Date.now(this.order.created_at),
          }
          this.printEmidaInvoice({ ...orderToPrint })
        }
      } else {
        window.print()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  body.modal-open {
    visibility: hidden;
  }

  body.modal-open .modal .modal-body {
    visibility: visible; /* make visible modal body and header */
  }
  #logo {
    filter: invert(1);
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .pos-layout {
    display: none !important;
  }
  .hide-print,
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.btnx {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
}

/* Primary button style */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* Secondary button style */
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border: none;
}

.btn-secondary:hover {
  background-color: #545b62;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
</style>
