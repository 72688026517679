var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"login-modal","body-class":"py-2 px-4","centered":"","hide-footer":""},on:{"hidden":_vm.resetForm}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-img',{staticClass:"logo",attrs:{"src":_vm.appLogoImage,"alt":"logo","width":"250"}})],1),_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" ¡Bienvenido! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Tu nueva forma de pagar y cobrar. ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email o Teléfono celular","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email_or_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : valid ? true : null,"name":"login-email","tabindex":"1","autocomplete":"username"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Contraseña")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v("¿Olvidaste tu contraseña?")])])],1),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : valid ? true : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password","tabindex":"2","autocomplete":"current-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-3",attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Iniciar sesión ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("¿Quieres comprar en línea? ")]),_c('b-link',{attrs:{"to":{ name: 'auth-register-client' }}},[_c('h4',[_c('span',{staticClass:"badge badge-success"},[_vm._v(" Registrate como cliente")])])])],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("¿Tienes un negocio y quieres usar nuestro sistema? ")]),_c('b-link',{attrs:{"to":{ name: 'auth-register-establishment' }}},[_c('h4',[_c('span',{staticClass:"badge badge-info"},[_vm._v(" Registrate como negocio")])])])],1),_c('div',{staticClass:"divider-text mt-2"}),_c('div',{staticClass:"auth-footer-btn d-flex justify-content-center text-center"},[_c('p',[_vm._v(" Al registrarse, acepta nuestros: "),_c('b-button',{attrs:{"block":"","to":{ name: 'terms-of-use' },"size":"sm"}},[_c('i',{staticClass:"fas fa-chart-line mr-1"}),_vm._v(" Términos y condiciones ")]),_c('b-button',{attrs:{"block":"","to":{ name: 'privacy-policy' },"size":"sm"}},[_c('i',{staticClass:"fas fa-chart-line mr-1"}),_vm._v(" Aviso de Privacidad ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }