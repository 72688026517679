var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"map",style:("width: 100%; height: 300px"),attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"roadmap","clickable":false,"options":{
    streetViewControl: false,
    fullscreenControl: true,
    restriction: {
      latLngBounds: {
        north: 32.718,
        south: 14.532,
        west: -118.503,
        east: -86.589,
      },
      strictBounds: false,
    },
  }}},[_c('GmapMarker',{attrs:{"position":_vm.marker,"clickable":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }